import * as React from "react";
import siteSettings from "../constants/siteSettings";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import devProcess from "./../assets/img/workflow.webp";

const DevelopmentProcess: React.FC = () => {
  return (
    <section id="testimonials" className="testimonials">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>{siteSettings.devTitle}</h2>
          <p className="mt-3" style={{ lineHeight: 1.5, fontSize: 18 }}>
            {siteSettings.devDescribe}
          </p>
        </header>
        <div className="row">
          <div className="col-md-12 text-center">
            <img
              src={devProcess}
              className="img-fluid"
              alt="Development Process of Light Infotech"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DevelopmentProcess;
