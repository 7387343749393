import * as React from "react";
import siteSettings from "../constants/siteSettings";
import img from "./../assets/img/about.jpeg";

interface FilterPortfolioProps {
  title: string;
  tagline: string;
  type?: string;
}

const FilterPortfolio: React.FC<FilterPortfolioProps> = ({
  type,
  title,
  tagline,
}) => {
  return (
    <section className="testimonials">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>{title}</h2>
          <p style={{ lineHeight: 1.5 }}>{tagline}</p>
        </header>
        <div className="row">
          {siteSettings.portfolio
            .filter((item, index) => item.type === type)
            .map((item, index) => (
              <div className="col-md-3">
                <div className="box">
                  <img
                    src={item.img}
                    style={{
                      maxHeight: 150,
                      objectFit: "cover",
                      width: "100%",
                    }}
                    className="img-fluid"
                  />
                  <div className="workDone text-center pt-2">
                    <h6>{item.title}</h6>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default FilterPortfolio;
