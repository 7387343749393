import * as React from "react";
import siteSettings from "../constants/siteSettings";

const WhyChooseUs: React.FC = () => {
  return (
    <section id="testimonials" className="values">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>{siteSettings.chooseUsTitle}</h2>
          <p>{siteSettings.chooseDescribe}</p>
        </header>
        <div className="row">
          {siteSettings.keyChoosePoint.map((item, index) => (
            <div
              key={index}
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="box">
                <h3>{item.title}</h3>
                <p className="mb-0">{item.describe}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
